import jwtDefaultConfig from "./jwtDefaultConfig";

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  role = null;

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              // Update accessToken in localStorage
              this.setToken(r.data.token);
              this.onAccessTokenFetched(r.data.token);
            });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((token) => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${token}`;
              resolve(this.axiosIns(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(token) {
    this.subscribers = this.subscribers.filter((callback) => callback(token));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return this.axiosIns.defaults.headers.common["Authorization"];
  }

  setToken(value) {
    this.axiosIns.defaults.headers.common["Authorization"] = `${this.jwtConfig.tokenType} ${value}`;
  }

  login(email, password) {
    this.setToken();
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, { email, password }, { withCredentials: true });
  }

  forgotPassword(email) {
    return this.axiosIns.post(this.jwtConfig.forgotPasswordEndpoint, { email });
  }

  resetPassword(params) {
    return this.axiosIns.post(this.jwtConfig.resetPasswordEndpoint, params);
  }

  validateResetToken(resetToken) {
    return this.axiosIns.post(this.jwtConfig.validateResetTokenEndpoint, { resetToken });
  }

  async logout() {
    this.setToken();
    await this.axiosIns.post(this.jwtConfig.logoutEndpoint, null, { withCredentials: true });
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  async refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, null, { withCredentials: true });
  }

  setRole(role) {
    this.role = role;
  }

  getRole() {
    return this.role;
  }

  isAdmin() {
    return this.role && this.role.includes("admin");
  }
}
