import useJwt from "@/auth/jwt/useJwt";
import jwt from "jsonwebtoken";
import store from "@/store";

export const isUserLoggedIn = async (ability) => {
  try {
    if (useJwt.axiosIns.defaults.headers.common["Authorization"] !== undefined) {
      const token = useJwt.axiosIns.defaults.headers.common["Authorization"];
      const decoded = jwt.decode(token.split(" ")[1]);
      const now = new Date();
      const exp = new Date(decoded.exp * 1000);
      if (now < exp) {
        return true;
      }
    }

    const res = await useJwt.refreshToken();
    const decoded = jwt.decode(res.data.token);
    useJwt.setToken(res.data.token);
    ability.update(decoded.ability);
    store.commit("verticalMenu/UPDATE_APP_NAME", decoded.appName);
    store.commit("verticalMenu/UPDATE_APP_LOGO_IMAGE", decoded.appLogoImage);
    return true;
  } catch (e) {
    return false;
  }
};
