import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/libs/acl/routeProtection";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const adminRoutes = [];
const agentRoutes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: () => import("@/views/Home.vue"),
  //   meta: {
  //     pageTitle: "Home",
  //     breadcrumb: [
  //       {
  //         text: "Home",
  //         active: true,
  //       },
  //     ],
  //     resource: "Home",
  //   },
  // },
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
      resource: "Auth",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      breadcrumb: [
        {
          text: "Profile",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/page",
    name: "page",
    component: () => import("@/views/page/Page.vue"),
    meta: {
      pageTitle: "Page",
      breadcrumb: [
        {
          text: "Page",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("@/views/customers/Index.vue"),
    meta: {
      pageTitle: "Customers",
      breadcrumb: [
        {
          text: "Customers",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/customers/add",
    name: "addCustomer",
    component: () => import("@/views/customers/Add.vue"),
    meta: {
      pageTitle: "Add Customer",
      breadcrumb: [
        {
          text: "Add Customer",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/customers/batch",
    name: "batchAddCustomer",
    component: () => import("@/views/customers/Batch.vue"),
    meta: {
      pageTitle: "Batch Add Customer",
      breadcrumb: [
        {
          text: "Batch Add Customer",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/referral",
    name: "referralSummary",
    component: () => import("@/views/agent/Referrals.vue"),
    meta: {
      pageTitle: "Referral Summary",
      breadcrumb: [
        {
          text: "Referral Summary",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/sales",
    name: "saleSummary",
    component: () => import("@/views/sales/Sales.vue"),
    meta: {
      pageTitle: "Sale Summary",
      breadcrumb: [
        {
          text: "Sale Summary",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/settlement/add",
    name: "addSettlement",
    component: () => import("@/views/settlement/AddSettlement.vue"),
    meta: {
      pageTitle: "Add Settlement",
      breadcrumb: [
        {
          text: "Add Settlement",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/settlements",
    name: "settlements",
    component: () => import("@/views/settlement/Settlements.vue"),
    meta: {
      pageTitle: "Settlements",
      breadcrumb: [
        {
          text: "Settlements",
          active: true,
        },
      ],
      resource: "Auth",
    },
  },
  {
    path: "settlements/:id",
    name: "settlement",
    component: () => import("@/views/settlement/Settlement.vue"),
    meta: {
      pageTitle: "Settlement",
      breadcrumb: [
        {
          text: "Settlement",
          active: true,
        },
      ],
      resource: "Auth",
    },
  },
  {
    path: "/job/add",
    name: "addJob",
    component: () => import("@/views/job/Add.vue"),
    meta: {
      pageTitle: "Batch Add Customer",
      breadcrumb: [
        {
          text: "Batch Add Customer",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/job",
    name: "allJobs",
    component: () => import("@/views/job/All.vue"),
    meta: {
      pageTitle: "Jobs",
      breadcrumb: [
        {
          text: "Jobs",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
  {
    path: "/job/:id",
    name: "job",
    component: () => import("@/views/job/One.vue"),
    meta: {
      pageTitle: "Job",
      breadcrumb: [
        {
          text: "Job",
          active: true,
        },
      ],
      resource: "Agent",
    },
  },
];
const commonRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/auth/Logout.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/resetPassword/:resetToken",
    name: "resetPassword",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "/error-401",
    name: "error-401",
    component: () => import("@/views/error/Error401.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [...commonRoutes, ...adminRoutes, ...agentRoutes],
});

router.beforeEach(async (to, _, next) => {
  const ability = router.app.$ability;
  const isLoggedIn = await isUserLoggedIn(ability);
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "login" });

    // If logged in => not authorized
    return next({ name: "error-401" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ path: "/" });
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
