export default {
  // Endpoints
  loginEndpoint: `${process.env.VUE_APP_API_URL}/account/authenticate`,
  registerEndpoint: `${process.env.VUE_APP_API_URL}/account/register`,
  refreshEndpoint: `${process.env.VUE_APP_API_URL}/account/refresh`,
  logoutEndpoint: `${process.env.VUE_APP_API_URL}/account/logout`,
  forgotPasswordEndpoint: `${process.env.VUE_APP_API_URL}/account/forgotPassword`,
  changePasswordEndpoint: `${process.env.VUE_APP_API_URL}/account/changePassowrd`,
  resetPasswordEndpoint: `${process.env.VUE_APP_API_URL}/account/resetPassword`,
  validateResetTokenEndpoint: `${process.env.VUE_APP_API_URL}/account/validateResetToken`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};
